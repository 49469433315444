import React, { useEffect, useState } from "react";

const App = () => {
  const [messages, setMessages] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Replace 'ws://your-esp8266-ip:4000' with the actual address of your WebSocket server
    const socket = new WebSocket("ws://192.168.137.180/ws");
    

    socket.onopen = () => {
      console.log("Connection opened");
    };
    socket.onclose = () => {
      console.log("Connection Closed");
    };

    socket.onmessage = (e) => {
      setMessages(e.data);
      console.log(typeof e.data);
    }; // <-- add this line

    setSocket(socket);

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const sendMessage = () => {
    if (socket && inputMessage.trim() !== "") {
      socket.send(inputMessage);
      setInputMessage("");
    }
  };

  return (
    <div>
      <h1>WebSocket Client</h1>
      <ul>
        <li>
          {messages[0] === "j" ? messages.slice(3, messages.length) : messages}
        </li>
      </ul>
      <div>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default App;
